import React from "react";
import ProfileList from "../../components/ProfileList";
import { Routes, Route, Link } from "react-router-dom";

 const Profile: React.FC = () => {
    return(
        <>
            <h2>プロフィール</h2>
                <ProfileList/>
        </>
    );
};
export default Profile;