import React from "react";
import Production_no_image from "../../components/ProductionList-noimage"

 const Production: React.FC = () => {
    return(
        <>
            <h2>イベント参加ページ</h2>
            <Production_no_image/>
        </>
    );
};
export default Production;