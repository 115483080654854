import React from "react";
import SkillList from "../../components/SkillList"

 const Skill: React.FC = () => {
    return(
        <>
            <h2>開発スキルページ</h2>
            <SkillList/>
        </>
    );
};
export default Skill;