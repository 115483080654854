import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Botton from "@mui/material/Button";
import Profile_image from "../assets/images/pro.jpeg"

const ProfileList: React.FC = () => {
    return (
        <>
            <Grid container rowSpacing={6} columnSpacing={6}>
                <Grid item xs={12}>
                    <Typography
                        component="h1"
                        variant="h3"
                        align="center"
                        sx={{
                            mb: 4,
                            fontWeight: 'bold',
                            borderBottom: '3px solid #4682b4',
                            pb: 2,
                            color: '#2E3B4E'
                        }}
                    >
                        プロフィール
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={{ width: "100%" }}>
                        <img style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "50%",
                            maxWidth: "250px",
                            margin: "0 auto"
                        }}
                            src={Profile_image} alt="Profile" />
                    </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                <Box sx={{ px: { xs: 0, md: 3 } }}>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        はじめまして
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        広島市立大学大学院1年の高田啓匡と申します。
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        <strong>趣味:</strong>
                        <ul style={{ marginTop: '8px', marginBottom: '16px' }}>
                            <li>サッカー</li>
                            <li>ギター</li>
                            <li>ゲーム</li>
                            <li>たまに読書</li>
                        </ul>
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        <strong>勉強中の内容:</strong>
                        <ul style={{ marginTop: '8px' }}>
                            <li>ネットワーク分野を研究</li>
                            <li>自宅ネットワークと自宅サーバ</li>
                            <li>Web系に興味を持ち挑戦中</li>
                        </ul>
                    </Typography>
                </Box>
            </Grid>
            </Grid>

        </>
    );
};
export default ProfileList;