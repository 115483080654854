import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Botton from "@mui/material/Button";

import MV from "../../assets/images/color_sora.jpg"

import SkillList from "../../components/SkillList"
import ProductionList from "../../components/ProductionList"
import ProfileList from "../../components/ProfileList";
import ContactForm from "../../components/ContactForm";
import Event_top from "../../components/Event_top";
import Belong_top from "../../components/Belong_top";
import Account_top from "../../components/Account_top";

const Top: React.FC = () => {
    return (
        <>
            <Box sx={{ height: "40vh", backgroundImage: "url(" + MV + ")", backgroundSize: "cover",backgroundPosition: "center", position: "relative" }}>
                <Container maxWidth='md' sx={{position: "absolute", top: "50%", left: "50%" ,transform: "translateX(-50%) translateY(-50%)"}}>
                    <Grid container rowSpacing={2} columnSpacing={2} sx={{ color: "#FFFFFF", textShadow: "1px 1px 3px #000000" }}>
                        <Grid item xs={12} md={12}>
                            <Typography component="h2" variant="h2" 
                            sx={{
                                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)", // 影を追加して立体的に見せる
                                color: "#fff", // 文字色を白に設定
                              }}>
                                ひろのポートフォリオサイト
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
                {/* <Botton variant="contained" size="large" sx={{position: "absolute", bottom: "10%", left: "50%" ,transform: "translateX(-50%)"}}>
                    LearnMore
                </Botton> */}
            </Box>
            {/* <Box>
                <Container maxWidth='md'>
                    <Grid container rowSpacing={0} columnSpacing={2}>
                        <SkillList/>
                    </Grid>
                </Container>
            </Box> */}
            <Box sx={{ marginTop: 5 }}>
                <Container maxWidth='md'>
                     <ProfileList /> 
                </Container>
            </Box>
            <Box>
                <Container maxWidth='md' sx={{ marginTop: 5 }}>
                    {/* <ProductionList /> */}
                    <Belong_top />
                </Container>
            </Box>
            <Box>
                <Container maxWidth='md' sx={{ marginTop: 5 }}>
                    {/* <ProductionList /> */}
                    <Event_top />
                </Container>
            </Box>
            <Box>
                <Container maxWidth='md' sx={{ marginTop: 5 }}>
                    <Account_top />
                </Container>
            </Box>
            <Box>
                <Container maxWidth='md' sx={{ marginTop: 5 }}>
                    <ContactForm />
                </Container>
            </Box>
        </>
    );
};
export default Top;