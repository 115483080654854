import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Botton from "@mui/material/Button";


const Event_top: React.FC = () => {
    return (
        <>
            <Grid container rowSpacing={4} columnSpacing={4}>
                <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>

                <Typography 
                    component="h1" 
                    variant="h3" 
                    align="center" 
                    sx={{ 
                        mb: 4, 
                        fontWeight: 'bold', 
                        borderBottom: '3px solid #4682b4', 
                        pb: 2,
                        color: '#2E3B4E'
                    }}
                >
                        イベント参加歴
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                <Box sx={{ textAlign: 'left', px: { xs: 0, md: 8 } }}>
                    <Typography variant="h5" gutterBottom>
                        IPv6
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        2023年/3月/AWSでつくるIPv6対応のWebサイト環境（IPv6ハンズオン）
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        2023年/8月/ 〜 2024年/3月/広島地域IPv6推進委員会 IPv6勉強会
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        2024年/7月/IPv6セミナー2024 Summer
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box sx={{ textAlign: 'left', px: { xs: 0, md: 8 } }}>
                    <Typography variant="h5" gutterBottom>
                        インターン・ハッカソン
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        2024年/1月/株式会社NTTドコモ 1day（インフラ）
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        2024年/4月/ＧＭＯインターネットグループ株式会社 3days（ハッカソン）
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        2024年/5月/RIZAPグループ株式会社 3days（フロントエンド）
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        2024年/6月/株式会社サイバーエージェント 2days（インフラ）
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        2024年/7月/JANOG54 Meeting in NARA 若者支援プログラム (ミーティング)
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        2024年/8月/ＧＭＯインターネットグループ株式会社 5days (インフラ)
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        2024年/8月/株式会社いい生活 5days (フロントエンド)
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        2024年/8月/セキュリティ・ミニキャンプ in 広島 (イベント)
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        2024年/9月/Brightech 2weeks (サーバサイド)
                    </Typography>
                </Box>
            </Grid>


            </Grid>

        </>
    );
};
export default Event_top;