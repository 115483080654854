import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import MediaCard_noimage from "../components/MediaCard-noimage";

//梅田さん
const MediaCard: React.FC<{title: string, description: string}> = ({title, description}) => {
    return (
        <div>
            <h2 style={{color: "gray"}}>{title}</h2>
            <p>{description}</p>
        </div>
        
    )
}

const ProductionList: React.FC =() =>{

    const setMediaList = [
        {
            'title': "株式会社NTTドコモ　1day",
            'description_content': `このワークショップでは、一人一人が仮想環境上に展開されたルータを実際に操作し、
            ミニISPネットワークの構築とトラフィック制御方法を体験`,
            'description_learn': `具体的には、CiscoとJunioerのルータを使用してルーティングプロトコルの設定を行いました。`
        },
        {
            'title': "GMOインターネットグループ株式会社 3days",
            'description_content': "LLMを利用したチャットボットの開発を4人1組のチーム開発",
            'description_learn': `初のグループワークかつ開発業務です。チームの人に助けてもらいフロントエンド業務を任せてもらいなんとかUIの部分を作成することができました。
            どういったものを作るかといった議論の部分で、積極的に意見を出せました。`
        },
        {
            'title': "RAIZAPグループ株式会社 3days",
            'description_content': "チーム開発によるchocoZAPのLPの作成",
            'description_learn': "HTML/CSSのコーディング技術、チーム開発におけるGithubの使い方を学び、LP作成方法を学ぶことができました。"
        },
        {
            'title': "株式会社サイバーエージェント 2days",
            'description_content': `クラウドのネットワークを構成する技術(DynamicRouting、VRRP、NATなど)やその仕組みについて取り上げ
            、それらの技術を組み合わせて仮想環境上でルータを複数構築しネットワークを構築`,
            'description_learn': `2人1組のチーム開発において、テーマにある技術をそれぞれ説明をしてもらい、資料を見ながら実装を行います。知識としては知っていることが多かったものの、実装経験が浅かったため、大変勉強になりました。
            メンターの方が2チームに1人ずつサポートしてくださり、質問がたくさんできる環境だったため、積極的に取り組むことができました。
            学校での学び方と、現場で働かれている方の経験は異なるため、どのようなことを考えて実装されているのかを意識しながら取り組みました。`
        },
        {
            'title': "JANOG54 Meeting 若者支援プログラム",
            'description_content': `JANOG54 Meetingに若者支援プログラムという枠で参加`,
            'description_learn': `JANOGとは、Japan Network Operators' Groupの略で
            インターネットに於ける技術的事項、および、それにまつわるオペレーションに関する事項を議論、検討、紹介することにより
            日本のインターネット技術者、および、利用者に貢献することを目的としたグループでです。引用元(https://www.janog.gr.jp/information/history/)。`
        }/* ,
        {
            'title': "タイトル3",
            'description_content': `タイトル1の説明が入ります。`,
            'description_learn': `タイトル1の説明が入ります。\nさらに説明を追加します。\nこんにちは。`
        } */
    ];

    return (
        <>
            <Grid container rowSpacing={4} columnSpacing={2}>
                <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
                <Typography 
                    component="h1" 
                    variant="h3" 
                    sx={{ 
                        mb: 4, 
                        fontWeight: 'bold', 
                        color: '#2E3B4E', 
                        borderBottom: '3px solid #4682b4', 
                        pb: 2 
                    }}
                >
                        イベント内容
                    </Typography>
                </Grid>
                {setMediaList.map((data) => {
                    return (
                        <Grid item xs={12} md={12}>
                            <Box sx={{width: "100%", height: "100%"}}>
                                <MediaCard_noimage title={data.title} description_content={data["description_content"]} description_learn={data["description_learn"]}/>
                            </Box>
                        </Grid>
                    );
                }
                )}
            </Grid>
        </>
    );
};
export default ProductionList;