import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';

interface MediaCardProps {
    title: string;
    description_content: string;
    description_learn: string;
}

const MediaCard: React.FC<MediaCardProps> = ({ title, description_content, description_learn }) => {
    return (
        <>
            <Card >
                <CardActionArea>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {title}
                        </Typography>
                        <Typography gutterBottom variant="body1" component="div">
                            <p>{description_content}</p>
                        </Typography>
                        <Typography gutterBottom variant="body1" component="div" color="text.secondary">
                            <p>{description_learn}</p>
                        </Typography>
                    </CardContent>
                </CardActionArea>

                <CardActions>
                    <Button size="small" color="primary">
                        Share
                    </Button>

                </CardActions>
            </Card>
        </>
    );
};

export default MediaCard;