import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const ContactForm: React.FC = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const sleep = (waitTime: number) => new Promise(resolve => setTimeout(resolve, waitTime));

    const handleEmailchange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handleMessagechange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(event.target.value);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            setIsSending(true);
            //送信する処理
            await sleep(5000);
            console.log("メールが送信されました。")
            //メール送信終わった後
            setEmailSent(true);
        } catch (error) {
            console.log(error);
        } finally {
            setIsSending(false);
        };
        console.log("送信されたメール: ", email)
        console.log("送信されたメッセージ: ", message)
    };

    return (
        <>
            <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography 
                    component="h1" 
                    variant="h3" 
                    sx={{ 
                        mb: 4, 
                        fontWeight: 'bold', 
                        color: '#2E3B4E', 
                        borderBottom: '3px solid #4682b4', 
                        pb: 2 
                    }}
                >
                    お問い合わせ
                </Typography>
            </Grid>
                <Grid item xs={12} md={12}>
                    {emailSent ? (
                        <Box sx={{ width: "100%", textAlign: "center" }}>
                            メールが送信されました。。
                        </Box>
                    ) : (
                        <Stack sx={{ width: "100%", 
                            maxWidth: "600px", 
                            mx: "auto", 
                            textAlign: "center", 
                            boxShadow: 3, 
                            p: 3, 
                            borderRadius: 2, 
                            backgroundColor: '#F9F9F9'  }} component="form" spacing={3} onSubmit={handleSubmit} autoComplete="off">
                            <TextField required fullWidth label="メールアドレス" name="email" variant="outlined" value={email} onChange={handleEmailchange} />
                            <TextField required fullWidth multiline rows={5} label="お問い合わせ内容" name="message" variant="outlined" value={message} onChange={handleMessagechange} />
                            {isSending ? (
                                <Button variant="contained" color="primary">
                                    <CircularProgress />
                                </Button>
                            ) : (
                                <Button type="submit" variant="contained" color="primary">
                                    送信
                                </Button>
                            )}
                        </Stack>
                    )}
                </Grid>
            </Grid>
        </>
    );
};
export default ContactForm;