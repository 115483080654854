import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Botton from "@mui/material/Button";

import MV from "../../assets/images/IMG_0809.jpg";
import RadarChart from "./RadarChart";

const SkillList: React.FC = () => {

    const setSkillLists = [
        {
            labels: ['C', 'TypeScript', 'HTML/CSS', 'C#', 'Python', 'Java', 'PHP', 'MySQL'],
            datasets: [
                {
                    label: 'プログラミング言語レベル',
                    data: [3, 1, 2, 1, 2, 1, 2, 3],
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1.0)',
                    borderWidth: 1,
                },
            ],

        },{
            labels: ['Apache', 'nginx', 'Linux', 'Docker', 'Github', 'DPDK'],
            datasets: [
                {
                    label: 'ミドルウェア・OS・その他',
                    data: [2, 2, 4, 2, 2, 1],
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1.0)',
                    borderWidth: 1,
                },
            ],
        }
    ];

    const setSkillListsCharOptions = {
        scales: {
            r: {
                max: 5,
                min: 0,
                ticks: {
                    stepSize: 1
                }
            }
        }
    }

    return (
        <>
            <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
                    <Typography
                        component="h1"
                        variant="h3"
                        sx={{
                            mb: 4,
                            fontWeight: 'bold',
                            color: '#2E3B4E',
                            borderBottom: '3px solid #4682b4',
                            pb: 2
                        }}
                    >
                        スキル
                    </Typography>
                </Grid>

                {setSkillLists.map((data) => {
                    return (
                        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{}}>
                                <RadarChart options={setSkillListsCharOptions} data={data} />
                            </Box>
                        </Grid>
                    );
                })}

            </Grid>
        </>
    );
};

export default SkillList;

