import React from "react";
import ContactForm from "../../components/ContactForm";

 const Contact: React.FC = () => {
    return(
        <>
            <h2>問い合わせページ</h2>
            <ContactForm/>
        </>
    );
};
export default Contact;