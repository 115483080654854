import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import MailIcon from '@mui/icons-material/Mail';
import Linkify from "linkify-react";

const Account_top: React.FC = () => {

    const linkifyOptions = {
        className: "text-blue-400",
    };

    
    function onClickHandleX() {
        window.open("https://x.com/t_hirotada", '_blank');
    }
    function onClickHandleGithub() {
        window.open("https://github.com/hiro-hcu", '_blank');
    }

    return (
        <>

            <Grid container rowSpacing={4} columnSpacing={4}>
                <Grid item xs={12}>
                    <Typography
                        component="h1"
                        variant="h3"
                        align="center"
                        sx={{
                            mb: 4,
                            fontWeight: 'bold',
                            borderBottom: '3px solid #4682b4',
                            pb: 2,
                            color: '#2E3B4E'
                        }}
                    >
                        アカウント
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                <Box sx={{ textAlign: { xs: 'center', md: 'left' }, px: { xs: 0, md: 8 } }}>
    <Typography variant="body1" color="text.secondary" paragraph>
        <MailIcon sx={{ verticalAlign: 'middle', mr: 1, color: '#333' }} />
        <strong>Gmail:</strong> hirotada.intern@gmail.com
    
    </Typography>
    <Typography variant="body1" color="text.secondary" paragraph>
        <TwitterIcon sx={{ verticalAlign: 'middle', mr: 1, color: '#1DA1F2' }} />
        <strong>Twitter (X):</strong>
        <span
            style={{ color: '#333', textDecoration: 'underline', cursor: 'pointer' }}
            
            onClick={onClickHandleX}
        >
            @t_hirotada
        </span>
    </Typography>
    <Typography variant="body1" color="text.secondary" paragraph>
        <GitHubIcon sx={{ verticalAlign: 'middle', mr: 1, color: '#333' }} />
        <strong>GitHub:</strong> 
        <span
            style={{ color: '#333', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={onClickHandleGithub}
            
        >
            hiro-hcu
        </span>
    </Typography>
</Box>

                </Grid>
            </Grid>

        </>
    );
};
export default Account_top;