import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Botton from "@mui/material/Button";


const Belong_top: React.FC = () => {
    return (
        <>
            <Grid container rowSpacing={4} columnSpacing={4}>
                <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
                    <Typography
                        component="h1"
                        variant="h3"
                        align="center"
                        sx={{
                            mb: 4,
                            fontWeight: 'bold',
                            borderBottom: '3px solid #4682b4',
                            pb: 2,
                            color: '#2E3B4E'
                        }}
                    >
                        所属
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                <Box sx={{ textAlign: 'left', px: { xs: 0, md: 8 } }}>
                    <Typography variant="h5" gutterBottom>
                        学校
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        2017年 近畿大学附属広島高等学校東広島校 入学
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        2020年 近畿大学附属広島高等学校東広島校 卒業
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        2020年 広島市立大学 情報科学部 入学
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        2024年 広島市立大学 情報科学部 情報工学科 卒業
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        2024年 広島市立大学大学院 情報科学研究科 情報工学専攻 入学
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        2026年 広島市立大学大学院 情報科学研究科 情報工学専攻 卒業予定
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ textAlign: 'left', px: { xs: 0, md: 8 } }}>
                    <Typography variant="h5" gutterBottom>
                        研究室
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        2022年10月〜 先端ネットワーク・セキュリティ研究グループ
                    </Typography>
                </Box>
            </Grid>    
         
            <Grid item xs={12}>
                <Box sx={{ textAlign: 'left', px: { xs: 0, md: 8 } }}>
                    <Typography variant="h5" gutterBottom>
                        クラブ・サークル
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        (中学・高校: サッカー部)
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        軽音楽部 (パート: ギター)
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        いちぴろ・エクスプローラ(技術系サークル)
                    </Typography>
                </Box>
            </Grid>
            </Grid>

        </>
    );
};
export default Belong_top;